export const getRoomNumber = (text) => {
  const regex = /- Habitacion /; 
  if (text) {
    const match = text.search(regex);
    if (match !== -1) {
      const numeroHabitacion = text.slice(match + 12);
      return numeroHabitacion;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
