import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { StyleSheet, Platform, Text, View, Dimensions } from "react-native";
import NavigatorHome from "./application/navigations/navigatorHome";

import store from "./application/reducers/store";
import { connect } from "react-redux";

import ImpresoraController from "./application/services/ImpresoraController";
import {
  SET_HEIGHT,
  SET_WIDTH,
} from "./application/reducers/screenDimensions/dimensionsTypes";

import * as ScreenOrientation from "expo-screen-orientation";
import Database from "./application/objetos/Database";
import { StatusBar, BackHandler } from "react-native";
import * as SQLite from "expo-sqlite";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as NavigationBar from "expo-navigation-bar";
import * as Device from "expo-device";
import Footer from "./application/components/Footer";

//import RNFetchBlob from 'react-native-fetch-blob';
import myCrypto from "./application/funcionalidades/myCrypto";
import { LanguageProvider } from "./application/contexts/LanguageContext";

global.pantallaDividida = true;
// let windowWidth = Dimensions.get("window").width;
// if (windowWidth < 600) {
//   global.pantallaDividida = false;
// }
global.socket;
global.usuario = null;

global.versionNumber = "1.8.124";
global.deviceName = Device.deviceName || "";
console.log("DEVICE!!!", Device);
// global.endpoint = "https://api.dev.gopick-app.com/";
global.endpoint = 'https://api.gopick-app.com/'
global.isDatafono = false;
if (Device.modelName == "Saturn1000F2") {
  global.isDatafono = true;
}

global.gestorAbierto = false;
global.impresoraController = new ImpresoraController();
global.filtrosAnteriores = "";

const App = (props: any) => {
  const [paginaCargada, setPaginaCargada] = useState(false);

  function cargarEventoOrientation() {
    ScreenOrientation.addOrientationChangeListener(function (result) {
      setTimeout(function () {
        store.dispatch({
          type: SET_HEIGHT,
          payload: {
            height: Dimensions.get("window").height,
          },
        });
        store.dispatch({
          type: SET_WIDTH,
          payload: {
            width: Dimensions.get("window").width,
          },
        });
      }, 10);
    });
  }

  async function desencriptar(respuesta: any) {
    console.log("respuesta", respuesta);
    var requestBody = respuesta.SaleToPOIResponse;

    let POIID = requestBody.MessageHeader.POIID;
    return POIID;
  }

  async function identificarName() {
    let serviceID = new Date().getTime().toString();
    let printRequest = {
      SaleToPOIRequest: {
        MessageHeader: {
          ProtocolVersion: "3.0",
          MessageClass: "Device",
          MessageCategory: "Diagnosis",
          MessageType: "Request",
          ServiceID: serviceID.substr(serviceID.length - 8),
          SaleID: "POSSystemID12345",
          POIID: global.deviceName,
        },
      },
    };
    console.log("printRequest", JSON.stringify(printRequest));

    myCrypto.getBlob(JSON.stringify(printRequest)).then(
      async function (res: any) {
        let result = {
          SaleToPOIRequest: {
            MessageHeader: {
              ProtocolVersion: "3.0",
              MessageClass: "Device",
              MessageCategory: "Diagnosis",
              MessageType: "Request",
              ServiceID: serviceID.substr(serviceID.length - 8),
              SaleID: "POSSystemID12345",
              POIID: "global.deviceName",
            },
            NexoBlob: res.mensaje,
            SecurityTrailer: {
              KeyVersion: 1,
              KeyIdentifier: "comandero",
              Hmac: res.hmac,
              Nonce: res.iv,
              AdyenCryptoVersion: 1,
            },
          },
        };
        console.log("result", JSON.stringify(result));
        RNFetchBlob.config({
          trusty: true,
        })
          .fetch(
            "POST",
            "https://localhost:8443/nexo",
            {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            JSON.stringify(result)
          )
          .then(async (res) => {
            let resultado = await desencriptar(await res.json());

            global.deviceName = resultado;
          })
          .catch((err) => {
            console.log("err", err);
          });
      },
      function (err) {
        console.log("err", err);
      }
    );
  }
  function conectarBBDD() {
    let db;
    try {
      db = SQLite.openDatabase(
        "gopickBBDD.db",
        "1.0",
        "My first database",
        2 * 1024 * 1024
      );
    } catch (error) {
      console.error(error);
    }

    return db;
  }

  async function cargarFiltrosAnteriores() {
    let filtrosAnteriores = await AsyncStorage.getItem("@filtrosAnteriores");
    if (
      filtrosAnteriores != "" &&
      filtrosAnteriores != undefined &&
      filtrosAnteriores != null
    ) {
      global.filtrosAnteriores = JSON.parse(filtrosAnteriores);
    }
    setPaginaCargada(true);
  }

  useEffect(() => {
    StatusBar.setBackgroundColor("black");
    NavigationBar.setVisibilityAsync("hidden");
    StatusBar.setHidden(true);
    global.db = conectarBBDD();
    cargarEventoOrientation();
    cargarFiltrosAnteriores();
    identificarName();
  }, []);

  return (
    <LanguageProvider>
      <View style={{ flex: 1 }}>
        {paginaCargada ? (
          <Provider store={store}>
            <NavigatorHome></NavigatorHome>

            {!global.pantallaDividida ? (
              <Footer navigation={props.navigation}></Footer>
            ) : null}
          </Provider>
        ) : null}
      </View>
    </LanguageProvider>
  );
};

export default App;
