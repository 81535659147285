import React from "react";
import {
  StyleSheet,
  Text,
  FlatList,
  View,
  useWindowDimensions,
} from "react-native";

const OrdersList = ({ list, title }) => {
  const { width } = useWindowDimensions();

  return (
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>{title}</Text>
      <FlatList
        data={list}
        numColumns={2}
        keyExtractor={(item) => item.toString()}
        renderItem={({ item }) => (
          <View style={[styles.item, { width: (width - 60) / 4 }]}>
            <Text style={styles.itemText}>{item.room}</Text>
          </View>
        )}
      />
    </View>
  );
};

export default OrdersList;

const styles = StyleSheet.create({
  section: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 12,
  },
  columnWrapper: {
    marginRight: 10,
  },
  item: {
    paddingVertical: 6,
    paddingHorizontal: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  sectionTitle: {
    fontSize: 60,
    fontWeight: "bold",
    marginBottom: 10,
    color: "#3AA597",
  },
  itemText: {
    fontSize: 130,
    marginBottom: 5,
    color: "#3AA597",
  },
});
