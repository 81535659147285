import React, { useEffect, useRef } from "react";
import { Platform, ScrollView, View, Text } from "react-native";
import { connect } from "react-redux";
import {
  setFacturaProductos,
  setFacturaComision,
  setPedidosParaEntregar,
  setPedidosPreparar,
  setPedidosFiltrados,
  setPedidosEnviados,
  setPedidosCompletados,
  setFooterVisible,
  setPedidos,
  addPedido,
  editarPedido,
  setStock,
  setPedidoImprimir,
  setConexion,
  setTiendas,
  setPedidosPendientes,
  setImpresoraDisponible,
  setTiendasCarrito,
  setSocketConectado,
  setPedidosPerdidos,
} from "../../application/reducers";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Pedido from "../objetos/Pedido";
import Socket from "../objetos/Socket";
import ProductoResumenCamarero from "../components/ProductoResumenCamarero";
import ProductoResumenDatafono from "../components/ProductoResumenDatafono";
import MenuButton from "../components/MenuButton";
import SvgQRCode from "react-native-qrcode-svg";
import Logo from "../../assets/icons/logo";
import NetInfo from "@react-native-community/netinfo";
import Tienda from "../objetos/Tienda";
import { captureRef } from "react-native-view-shot";
import { Audio } from "expo-av";
import { Buffer } from "buffer";
import globales from "../funcionalidades/globales";
import myCrypto from "../funcionalidades/myCrypto";

let intervalosAlarmas = [];
let intervaloTiempo = "";

type UseModes = "tpv" | "waiter" | "foodStall";

const menu = (props: any) => {
  function parseJwt(token) {
    return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
  }
  let localizacionesMarcadasFiltros = [];
  global.ticketRefDatafono = useRef(null);
  global.ticketRefFacturaComision = useRef(null);
  global.ticketRefFacturaProductos = useRef(null);
  var ticketRefCliente = useRef(null);
  var ticketRefManager = useRef(null);

  function abrirPedidos() {
    let pruebas = parseJwt(
      "eyJraWQiOiJXNldjT0tCIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiYXVkIjoiY29tLmdvcGljay51c2VycyIsImV4cCI6MTY4MDAwNDM4NywiaWF0IjoxNjc5OTE3OTg3LCJzdWIiOiIwMDAzMDMuNTYzM2Q5ZDU3YmM3NGY1ZGI4YTE0NzhhMDI3ZThlNGYuMTAxNyIsImNfaGFzaCI6Im1qYlBBNzZBc2VwdjhRbmlsYU41SFEiLCJlbWFpbCI6ImVsb3kuZ29tZXpAZ29waWNrLWFwcC5jb20iLCJlbWFpbF92ZXJpZmllZCI6InRydWUiLCJhdXRoX3RpbWUiOjE2Nzk5MTc5ODcsIm5vbmNlX3N1cHBvcnRlZCI6dHJ1ZX0.qIMo2MlNCciTa0cGCqACDGeFnQ4cCpEhbrI6QosXWPhSODdomioc7xlKrKmTL1hdKznrdBD0WY8Ol4aIYbWgLur6f47N-59VBduEUtL8hBwtxFr2hL_GPBkiGo9E0ca8booXXfqDAwizQHwEDkAY0emJFongVW3eOF3HKrXv4SFZx4tG_Ujr9VJW1MzaLWGaAmc89JbqIQJf2bdP1NhCjREOdofGfMKetCFZcEYgHco9cwgHonrFnh12gi3XVOv-3LN6Ydv4_0VNxE7Xetj5Dr2PlR1lRE_gFOrQ5pI_PVxSyZrLgwqYNnNcVayU6MnFq5w2AuF08aLT5RiD1DnKhw"
    );
    console.log("pruebas", pruebas);
    props.navigation.navigate("gestionPedidos");
  }
  function pintarDescuento(descuentos: any) {
    let totalDescuento = 0;
    descuentos.map(function (item: any, index: any) {
      if (item.amount != undefined && item.amount != null) {
        if (item.amount < 0) {
          totalDescuento = totalDescuento + item.amount;
        }
      }
    });
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 4,
          marginBottom: 4,
        }}
      >
        <View style={{ flex: 1, flexDirection: "row" }}>
          <Text style={{ fontSize: 15 }}>Descuento</Text>
        </View>
        <Text style={{ fontSize: 15 }}>
          {(totalDescuento / 100).toFixed(2)}€
        </Text>
      </View>
    );
  }
  function comprobarTiendas(mode: UseModes, tiendasAux: any) {
    if (tiendasAux.length == 1) {
      if (mode === "tpv") {
        abrirTienda(tiendasAux[0], mode);
      } else {
        onPressTienda(tiendasAux[0], mode);
      }
    } else {
      let params = {
        mode: mode,
        tiendas: tiendasAux,
      };
      props.navigation.navigate("listadoTiendas", params);
    }
  }
  function abrirTiendas(mode: UseModes) {
    global.tpv = mode === "tpv";
    if (props.conexion) {
      global.usuario.cargarTiendas().then(function (res: any) {
        let tiendasAux: Array<any> = [];
        res.map(function (tienda: any, indice: number) {
          tiendasAux.push(new Tienda(tienda));
        });
        props.setTiendas(tiendasAux);
        comprobarTiendas(mode, tiendasAux);
      });
    } else {
      comprobarTiendas(mode, props.tiendas);
    }
  }
  global.imprimirTicket = async function (tipoManager: any, ref: any) {
    if (props.pedidoImprimir != null && props.pedidoImprimir != undefined) {
      if (Platform.OS != "web" && !global.isDatafono) {
        if (!ref) {
          ref = ticketRefCliente;
          if (tipoManager) {
            ref = ticketRefManager;
          }
        }

        console.log("compro", props.pedidoImprimir);
        if (!global.isDatafono) {
          await comprobarImpresora(tipoManager, ref);
        }
      }
    }
  };

  function borrarRedux() {
    props.setPedidoImprimir(null);
    props.setTiendas([]);
    props.setConexion(true);
    props.setPedidos([]);
    props.setTiendasCarrito([]);
  }
  async function comprobarImpresora(tipoManager: any, ref: any) {
    let address = await AsyncStorage.getItem("@address_impresora");

    if (address != undefined && address != null && address != "") {
      let res = await global.impresoraController.conectarImpresora(address);
      const result = await captureRef(ref, {
        result: "base64",
        quality: 1,
        format: "png",
      });

      if (res) {
        await global.impresoraController.imprimirTicket(result);
      } else {
        global.impresoraController.colaImpresion.push(result);
        if (global.impresoraController.colaImpresion.length <= 1) {
          abrirListadoBluetooth(tipoManager);
        }
      }
    } else {
      const result = await captureRef(ref, {
        result: "base64",
        quality: 1,
        format: "png",
      });
      global.impresoraController.colaImpresion.push(result);
      abrirListadoBluetooth(tipoManager);
    }
  }
  function imprimirCola(address) {
    global.impresoraController
      .conectarImpresora(address)
      .then(async function (res: any) {
        global.impresoraController.imprimirCola();
      });
  }
  function abrirListadoBluetooth(tipoManager: any) {
    // let aux = { eloy todo remove comment
    //   impresoraSeleccionada: (address: any) => imprimirCola(address),
    // };
    // props.navigation.navigate("listadoBluetooth", aux);
  }
  function abrirListadoPedidosCamarero() {
    props.navigation.navigate("listadoPedidosDatafono");
  }
  function comprobarMarcadas(opciones: any, localizacionesMarcadas: any) {
    let hayMarcadas = false;
    let marcada;
    opciones.map(function (opcion: any, index: any) {
      if (opcion.marcado) {
        hayMarcadas = true;
        marcada = opcion;
        comprobarMarcadas(
          opcion.arrayOpciones,
          localizacionesMarcadas + " " + opcion.value
        );
      }
    });
    if (!hayMarcadas) {
      if (localizacionesMarcadas.length > 0) {
        localizacionesMarcadasFiltros.push(localizacionesMarcadas);
      }
    }
  }
  function comprobarFiltro(pedido: any) {
    let cumpleFiltro = false;
    let filtrosTiendas = {};
    if (
      global.usuario.filtros.tiendas != undefined &&
      global.usuario.filtros.tiendas != null
    ) {
      if (global.usuario.filtros.tiendas.length > 0) {
        global.usuario.filtros.tiendas.map(function (tienda: any, index: any) {
          if (tienda.marcado) {
            let localizacionesMarcadas = [];
            filtrosTiendas[tienda.gid] = [];
            tienda.localizaciones.map(function (localizacion: any, index: any) {
              comprobarMarcadas(localizacion.arrayOpciones, "");
              if (localizacionesMarcadasFiltros.length > 0) {
                filtrosTiendas[tienda.gid].push(localizacionesMarcadasFiltros);
                localizacionesMarcadasFiltros = [];
              }
            });
          }
        });
        let indice = Object.keys(filtrosTiendas).indexOf(
          pedido.id_shop.toString()
        );
        if (indice != -1) {
          if (filtrosTiendas[Object.keys(filtrosTiendas)[indice]].length > 0) {
            filtrosTiendas[Object.keys(filtrosTiendas)[indice]].map(function (
              localizacionesPermitidas: any,
              index: any
            ) {
              localizacionesPermitidas.map(function (
                localizacionStr: any,
                index: any
              ) {
                if (pedido.location.includes(localizacionStr.trim())) {
                  cumpleFiltro = true;
                }
              });
            });
          } else {
            cumpleFiltro = true;
          }
        }
      } else {
        cumpleFiltro = true;
      }
    } else {
      cumpleFiltro = true;
    }

    return cumpleFiltro;
  }
  async function comprobarImpresion(pedido: any, opcion: any) {
    if (global.usuario.setting != undefined && global.usuario.setting != null) {
      if (global.usuario.setting.print != undefined) {
        let deboImprimir = false;
        pedido.productos.map(function (item: any) {
          if (!item.producto.oculto) {
            deboImprimir = true;
          }
        });
        if (deboImprimir) {
          if (comprobarFiltro(pedido)) {
            global.usuario.setting.print.map(async function (
              print: any,
              index: number
            ) {
              if (
                pedido.state == print.status ||
                pedido.prioridadesPedidoImpresion[pedido.state] >
                  pedido.prioridadesPedidoImpresion[print.status]
              ) {
                if (opcion != "tvp") {
                  pedido.impreso = true;
                  addPedidoImpreso(pedido);
                }
                props.editarPedido(pedido);
                for (let i = 0; i < parseInt(print.quantity); i++) {
                  if (print.type == "manager") {
                    global.impresoraController.addColaPedidos(
                      pedido,
                      props.setPedidoImprimir,
                      true
                    );
                  } else {
                    global.impresoraController.addColaPedidos(
                      pedido,
                      props.setPedidoImprimir,
                      false
                    );
                  }
                }
              }
            });
          }
        }
      }
    }
  }
  async function comprobarPedidoSonado(pedido: any) {
    let pedidosSonados = await AsyncStorage.getItem("@pedidosSonados");
    let existe = -1;
    if (pedidosSonados != undefined && pedidosSonados != null) {
      pedidosSonados = JSON.parse(pedidosSonados);
      if (
        pedidosSonados.pedidos != undefined &&
        pedidosSonados.pedidos != null
      ) {
        pedidosSonados.pedidos.map(function (pedidoItem: any, index: any) {
          if (pedidoItem.gid == pedido.gid) {
            existe = index;
          }
        });
      }
    }
    if (existe != -1) {
      return true;
    } else {
      return false;
    }
  }
  async function comprobarPedidoImpreso(pedido: any) {
    let pedidosImpresos = await AsyncStorage.getItem("@pedidosImpresos");
    let existe = -1;
    if (pedidosImpresos != undefined && pedidosImpresos != null) {
      pedidosImpresos = JSON.parse(pedidosImpresos);
      if (
        pedidosImpresos.pedidos != undefined &&
        pedidosImpresos.pedidos != null
      ) {
        pedidosImpresos.pedidos.map(function (pedidoItem: any, index: any) {
          if (pedidoItem.gid == pedido.gid) {
            existe = index;
          }
        });
      }
    }
    if (existe != -1) {
      return true;
    } else {
      return false;
    }
  }
  async function addPedidoSonados(pedido: any) {
    let pedidosSonados = await AsyncStorage.getItem("@pedidosSonados");
    let pedidosSonadosAux;
    if (pedidosSonados != undefined && pedidosSonados != null) {
      pedidosSonados = JSON.parse(pedidosSonados);

      let msSemana = 7 * 24 * 3600;
      let timestampNow = new Date().getTime() / 1000;
      let timestampSemana = timestampNow - msSemana;
      let pedidosAux = [{ gid: pedido.gid, order_date: pedido.order_date }];
      if (
        pedidosSonados.pedidos != undefined &&
        pedidosSonados.pedidos != null
      ) {
        pedidosSonados.pedidos.map(function (pedido: any, index: any) {
          if (pedido.order_date >= timestampSemana) {
            pedidosAux.push(pedido);
          }
        });
      }
      pedidosSonadosAux = { pedidos: pedidosAux };
    } else {
      pedidosSonadosAux = {
        pedidos: [{ gid: pedido.gid, order_date: pedido.order_date }],
      };
    }
    AsyncStorage.setItem("@pedidosSonados", JSON.stringify(pedidosSonadosAux));
  }

  async function addPedidoImpreso(pedido: any) {
    let pedidosImpresos = await AsyncStorage.getItem("@pedidosImpresos");
    let pedidosImpresosAux;
    if (pedidosImpresos != undefined && pedidosImpresos != null) {
      pedidosImpresos = JSON.parse(pedidosImpresos);

      let msSemana = 7 * 24 * 3600;
      let timestampNow = new Date().getTime() / 1000;
      let timestampSemana = timestampNow - msSemana;
      let pedidosAux = [{ gid: pedido.gid, order_date: pedido.order_date }];
      if (
        pedidosImpresos.pedidos != undefined &&
        pedidosImpresos.pedidos != null
      ) {
        pedidosImpresos.pedidos.map(function (pedido: any, index: any) {
          if (pedido.order_date >= timestampSemana) {
            pedidosAux.push(pedido);
          }
        });
      }
      pedidosImpresosAux = { pedidos: pedidosAux };
    } else {
      pedidosImpresosAux = {
        pedidos: [{ gid: pedido.gid, order_date: pedido.order_date }],
      };
    }
    AsyncStorage.setItem(
      "@pedidosImpresos",
      JSON.stringify(pedidosImpresosAux)
    );
  }
  function cargarSocket() {
    global.socket = new Socket();
    global.socket.conectarSocketProductos().then(function () {
      global.socket.socketProductos.on("products:runout:result", (res: any) => {
        let pedido = -1;
        props.pedidos.map(function (item: any, index: number) {
          if (item.gid == res.data.order_id) {
            pedido = item;
          }
        });

        if (pedido != -1) {
          pedido.productos.map(function (producto: any, index: number) {
            res.data.order_lines_gid.map(function (
              order_line_id: any,
              index: number
            ) {
              if (producto.gid == order_line_id) {
                producto.producto.agotado = true;
                producto.producto.state = "out_of_stock";
              }
            });
          });
          props.editarPedido(pedido);
        }
      });
    });
    global.socket.conectarSocket().then(async function () {
      global.socket.socket.on("connect", () => {
        console.log("socket conectado");
        global.socket.socket.conectado = true;
        props.setSocketConectado(true);
      });
      global.socket.socket.on("connect_error", (err: any) => {
        console.log("connect_error", err);
        global.socket.socket.conectado = false;
        props.setSocketConectado(false);
        props.setPedidosPerdidos(true);
      });
      global.socket.socket.on("orders:read:result", async (res: any) => {
        console.log(",readread,result", res);
        let pedidos = [];
        let pedidosAux = res.data.orders || [];
        let ultimoCierre = await AsyncStorage.getItem("@ultimoCierre");
        pedidosAux.map(function (pedido: any, indice: number) {
          if (
            pedido.order_date >= parseFloat(ultimoCierre) ||
            parseFloat(ultimoCierre || "0") == 0
          ) {
            if (pedido.order_lines != undefined) {
              if (pedido.order_lines.length > 0) {
                pedidos.push(new Pedido(pedido));
              }
            }
          }
        });

        props.setPedidos(pedidos);
        props.setPedidosFiltrados(pedidos);
        let pedidosAgrupados = globales.agruparPedidosEstado(pedidos);
        props.setPedidosParaEntregar(pedidosAgrupados.pedidosParaEntregar);
        props.setPedidosEnviados(pedidosAgrupados.pedidosEnviados);
        props.setPedidosPreparar(pedidosAgrupados.pedidosPreparar);
        props.setPedidosCompletados(pedidosAgrupados.pedidosCompletados);
      });
      global.socket.socket.emit("orders:read", {});

      global.socket.socket.on("orders:recent", (res: any) => {
        let pedido;
        console.log("orders:recent", res);
        if (res.data != undefined && res.data[0] != undefined) {
          if (res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              let item = res.data[i];
              let pedido = new Pedido(item);
              props.addPedido(pedido);
              comprobarImpresion(pedido, "tpv");
            }
          }
        } else {
          pedido = new Pedido(res.data);
          props.addPedido(pedido);
          comprobarImpresion(pedido, "tpv");
        }
      });

      global.socket.socket.on("orders:createPaid:result", async (res: any) => {
        console.log("orders:createPaid", res);
        if (res.status == "fail") {
          //alert(res.message)
        } else {
          //alert("Pedido creado correctamente")
          if (res.data != undefined && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              let item = res.data[i];
              let pedido = new Pedido(item);
              pedido.sincronizado = true;
              guardarPedidoSincronizadoBBDD(pedido);
              if (global.isDatafono) {
                reImprimirFacturas(pedido);
              } else {
                if (
                  global.usuario.setting != undefined &&
                  global.usuario.setting != null
                ) {
                  if (!global.tpv) {
                    if (global.usuario.setting.waiter_print == 1) {
                      global.impresoraController.addColaPedidos(
                        pedido,
                        props.setPedidoImprimir
                      );
                    }
                  } else {
                    if (global.usuario.setting.tpv_print == 1) {
                      global.impresoraController.addColaPedidos(
                        pedido,
                        props.setPedidoImprimir
                      );
                    }
                  }
                }
              }
            }
          }
        }
      });
    });
  }

  async function comprobarAlarmas(pedidos: any) {
    if (global.usuario.setting.alarm != undefined) {
      for (let i = 0; i < global.usuario.setting.alarm.length; i++) {
        let alarm = global.usuario.setting.alarm[i];
        let existe = -1;
        for (let i = 0; i < pedidos.length; i++) {
          let pedido = pedidos[i];
          if (pedido.state == alarm.status) {
            let existe2 = await comprobarPedidoSonado(pedido);

            if (!existe2) {
              pedido.haSonado = true;
              addPedidoSonados(pedido);
              existe = i;
            }
          }
        }

        if (existe != -1) {
          let existeIntervalo = -1;
          intervalosAlarmas.map(function (intervaloAlarma: any, index: number) {
            if (intervaloAlarma.status == alarm.status) {
              existeIntervalo = index;
            }
          });

          if (existeIntervalo != -1) {
            clearInterval(intervalosAlarmas[existeIntervalo].intervalo);
            intervalosAlarmas[existeIntervalo] = {
              status: alarm.status,
              intervalo: setInterval(async function () {
                if (intervalosAlarmas[existeIntervalo].contador < alarm.times) {
                  intervalosAlarmas[existeIntervalo].contador++;
                  await sonarAlarma(alarm.status);
                } else {
                  clearInterval(intervalosAlarmas[existeIntervalo].intervalo);
                }
              }, 5000),
              contador: 0,
            };
            intervalosAlarmas[existeIntervalo].contador++;
            await sonarAlarma(alarm.status);
          } else {
            let intervaloAlarma = {
              status: alarm.status,
              intervalo: setInterval(async function () {
                if (intervaloAlarma.contador < alarm.times) {
                  intervaloAlarma.contador++;
                  await sonarAlarma(alarm.status);
                } else {
                  clearInterval(intervaloAlarma.intervalo);
                }
              }, 5000),
              contador: 0,
            };
            intervalosAlarmas.push(intervaloAlarma);
            intervaloAlarma.contador++;
            await sonarAlarma(alarm.status);
          }
        }
      }
    }
  }
  async function sonarAlarma(status: any) {
    let sound;
    switch (status) {
      case "waiting":
        sound = await Audio.Sound.createAsync(
          require("../../assets/sounds/enespera.mp3")
        );
        break;
      case "processing":
        sound = await Audio.Sound.createAsync(
          require("../../assets/sounds/enproceso.mp3")
        );
        break;
      case "await_pickup":
        sound = await Audio.Sound.createAsync(
          require("../../assets/sounds/listopararecoger.mp3")
        );
        break;
      case "completed":
        sound = await Audio.Sound.createAsync(
          require("../../assets/sounds/completado.mp3")
        );
        break;
    }
    await sound.sound.playAsync();
  }
  useEffect(() => {
    if (global.socket != undefined) {
      if (global.socket.socket.conectado) {
        global.socket.socket.off("orders:update:result");
        global.socket.socket.on("orders:update:result", async (res: any) => {
          console.log("orders:update:result", res);
          let pedido = -1;

          props.pedidos.map(function (item: any, index: number) {
            if (item.gid == res.data.gid) {
              pedido = item;
              pedido.state = res.data.status;
            }
          });
          if (pedido != -1) {
            props.editarPedido(pedido);
            pedido.cargarEstado();
            //let indicePedidoUsuario = global.usuario.pedidosEstadosCambiados.indexOf(pedido.gid)

            //if(indicePedidoUsuario!=-1){
            let comprobacion = await comprobarPedidoImpreso(pedido);
            if (!comprobacion) {
              comprobarImpresion(pedido);
            }
            //global.usuario.pedidosEstadosCambiados.splice(indicePedidoUsuario, 1);
            //}
          }
        });
        global.socket.socketProductos.off("products:runout:result");
        global.socket.socketProductos.on(
          "products:runout:result",
          (res: any) => {
            let pedido = -1;
            props.pedidos.map(function (item: any, index: number) {
              if (item.gid == res.data.order_id) {
                pedido = item;
              }
            });

            if (pedido != -1) {
              pedido.productos.map(function (producto: any, index: number) {
                res.data.order_lines_gid.map(function (
                  order_line_id: any,
                  index: number
                ) {
                  if (producto.producto.gid == order_line_id) {
                    producto.producto.agotado = true;
                    producto.producto.state = "out_of_stock";
                  }
                });
              });
              props.editarPedido(pedido);
            }
          }
        );
      }
    }
    comprobarAlarmas(props.pedidos);
    //comprobarTiempos(props.pedidos)
  }, [props.pedidos]);
  useEffect(() => {
    props.setFooterVisible(true);
    props.navigation.setOptions({
      headerTitle: global.usuario.email,
      headerLeft: false,
    });

    const unsubscribe = NetInfo.addEventListener((state) => {
      if (!state.isConnected) {
        props.setConexion(false);
      } else {
        props.setConexion(true);
      }
    });
    if (global.socket == undefined || global.socket == "") {
      cargarSocket();
      cargarStock();
      cargarTiendasAll();
    }
  }, []);
  function comprobarTiempos(pedidos) {
    if (intervaloTiempo != "") {
      clearInterval(intervaloTiempo);
    }
    intervaloTiempo = setInterval(function () {
      pedidos.map(function (item: any, index: number) {
        item.calcularTiempo();
        props.editarPedido(item);
      });
    }, 60000);
  }
  /*useEffect(() => {
    console.log("se ha actualizado el redux de imprimir pedido",props.pedidoImprimir)
    global.imprimirTicket(props.referenciaTicket)
  }, [props.pedidoImprimir]);*/
  function cargarStock() {
    global.usuario.cargarStock().then(function (res: any) {
      if (res != undefined && res != null) {
        props.setStock(res);
      }
    });
  }
  function cargarTiendasAll() {
    let fechaAhora = new Date().getTime();
    global.usuario.cargarTiendas().then(async function (res: any) {
      let tiendasAux: Array<any> = [];
      for (let i = 0; i < res.length; i++) {
        let item = res[i];
        let tienda = new Tienda(item);
        await tienda.getDatosTienda(tienda.gid, "pick_up").then(function () {
          tiendasAux.push(tienda);
        });
      }
      let fechaAhora2 = new Date().getTime();
      console.log("final", tiendasAux, (fechaAhora2 - fechaAhora) / 1000);
      props.setTiendas(tiendasAux);
      /*res.map(function(item:any,indice:number) {
        let tienda = new Tienda(item)
        tienda.getDatosTienda(tienda.gid,'pick_up').then(function(res:any){
          tiendasAux.push(tienda)
        })
      });*/
    });
  }
  function abrirEscaner() {
    props.navigation.navigate("vistaEscaner");
  }
  function onPressTienda(tienda: any, mode?: UseModes) {
    if (tienda.services != undefined && tienda.services.length > 0) {
      if (tienda.services.length == 1) {
        if (
          tienda.services[0].structure != undefined &&
          tienda.services[0].structure.length > 0
        ) {
          abrirServicios(tienda);
        } else {
          tienda.servicioSel = tienda.services[0].key;
          abrirTienda(tienda, mode);
        }
      } else {
        abrirServicios(tienda);
      }
    } else {
      abrirTienda(tienda, mode);
    }
  }

  function abrirTienda(tienda: any, mode?: UseModes) {
    let aux = {
      tienda: tienda,
    };
    if (props.conexion) {
      tienda.getDatosTienda(tienda.gid, "pick_up").then(function (res: any) {
        if (tienda.products.length > 0) {
          if (mode === "foodStall") {
            props.navigation.navigate("FoodStallScreen", aux);
          } else props.navigation.navigate("detallesTienda", aux);
        } else {
          alert("La tienda seleccionada no tiene productos");
        }
      });
    } else {
      if (tienda.products.length > 0) {
        props.navigation.navigate("detallesTienda", aux);
      } else {
        alert("La tienda seleccionada no tiene productos");
      }
    }
  }
  global.cerrarSesion = function () {
    AsyncStorage.setItem("@email_usuario", "");
    AsyncStorage.setItem("@pass_usuario", "");
    //AsyncStorage.setItem('@filtrosAnteriores', '');

    props.navigation.reset({
      index: 0,
      routes: [{ name: "login" }],
    });

    global.socket.socket.off("orders:recent");
    global.socket.socket.off("orders:createPaid:result");
    global.socket.socket.offAny();
    global.socket.socket.disconnect();
    global.socket.socketProductos.disconnect();
    global.socket = undefined;
    borrarRedux();
    ///DevSettings.reload()
  };
  /* function abrirRedireccion(){
    let aux={
      uri:"https://www.youtube.es"
    }
    props.navigation.push('redirigirUsuario',aux)
  }
  //useEffect(() => {
    abrirRedireccion()
  //}, []);
  */
  function abrirServicios(tienda: any) {
    let aux = {
      tienda: tienda,
    };
    console.log("seleccionServicios go-detallesTienda");
    props.navigation.navigate("seleccionServicios", aux);
  }
  function pintarTicketCliente() {
    return (
      <View
        collapsable={false}
        ref={(ref) => {
          ticketRefCliente = ref;
        }}
        style={{ width: 350, backgroundColor: "white", padding: 10 }}
      >
        <Logo width="100" height="50" />
        <Text
          style={{
            color: "#3AA597",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          {props.pedidoImprimir.order_number}
        </Text>
        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 18,
          }}
        >
          {props.pedidoImprimir.shop.name}
        </Text>
        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 15,
          }}
        >
          {props.pedidoImprimir.fechaEntrada}
        </Text>
        <Text style={{ fontSize: 15, paddingTop: 4, paddingBottom: 4 }}>
          {props.pedidoImprimir.hora}
        </Text>

        {props.pedidoImprimir.location != "" ? (
          <View
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              Localización: {props.pedidoImprimir.location}
            </Text>
          </View>
        ) : null}
        {props.pedidoImprimir.comment != undefined &&
        props.pedidoImprimir.comment != "" ? (
          <View
            style={{
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <Text style={{ fontSize: 15, fontWeight: "bold" }}>
              Comentario:
            </Text>
            <Text style={{ fontSize: 15 }}>{props.pedidoImprimir.comment}</Text>
          </View>
        ) : null}
        <View
          style={{
            marginBottom: 10,
            marginTop: 10,
            height: 1,
            backgroundColor: "black",
            width: "100%",
          }}
        ></View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text>Uds producto</Text>
          <Text>Importe</Text>
        </View>

        <View>
          {props.pedidoImprimir.productos.map(function (item: any) {
            if (!item.producto.oculto) {
              return (
                <ProductoResumenCamarero
                  sinPrecio={false}
                  disabled={true}
                  lineaPedido={item}
                ></ProductoResumenCamarero>
              );
            }
          })}
        </View>
        <View
          style={{
            marginBottom: 10,
            marginTop: 10,
            height: 1,
            backgroundColor: "black",
            width: "100%",
          }}
        ></View>
        <View
          style={{
            padding: 10,
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <View style={{ width: "75%" }}>
            {props.pedidoImprimir.order_lines_commission.map(function (
              item: any,
              index: any
            ) {
              if (item.amount > 0) {
                return (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 4,
                      marginBottom: 4,
                    }}
                  >
                    <View style={{ flex: 1, flexDirection: "row" }}>
                      <Text style={{ fontSize: 15 }}>{item.name}</Text>
                    </View>
                    <Text style={{ fontSize: 15 }}>
                      {(item.amount / 100).toFixed(2)}€
                    </Text>
                  </View>
                );
              }
            })}
            {props.pedidoImprimir.order_lines_service.map(function (
              item: any,
              index: any
            ) {
              if (item.amount > 0) {
                return (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 4,
                      marginBottom: 4,
                    }}
                  >
                    <View style={{ flex: 1, flexDirection: "row" }}>
                      <Text style={{ fontSize: 15 }}>{item.name}</Text>
                    </View>
                    <Text style={{ fontSize: 15 }}>
                      {(item.amount / 100).toFixed(2)}€
                    </Text>
                  </View>
                );
              }
            })}

            {props.pedidoImprimir.order_lines_discount.length > 0
              ? pintarDescuento(props.pedidoImprimir.order_lines_discount)
              : null}

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              <Text style={{ fontSize: 18 }}>
                TOTAL <Text style={{ fontSize: 15 }}>(Impuestos Inc.)</Text>
              </Text>
              <Text style={{ fontSize: 18 }}>
                {props.pedidoImprimir.calcularPrecioPedido().toFixed(2) + "€"}
              </Text>
            </View>
            {props.pedidoImprimir.payment_method == "dataphone" ||
            props.pedidoImprimir.payment_method == "card" ? (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 4,
                  marginBottom: 4,
                }}
              >
                <View style={{ flex: 1, flexDirection: "row" }}>
                  <Text style={{ fontSize: 15 }}>Forma de pago</Text>
                </View>
                <Text style={{ fontSize: 15 }}>Tarjeta</Text>
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 4,
                  marginBottom: 4,
                }}
              >
                <View style={{ flex: 1, flexDirection: "row" }}>
                  <Text style={{ fontSize: 15 }}>Forma de pago</Text>
                </View>
                <Text style={{ fontSize: 15 }}>Efectivo</Text>
              </View>
            )}
          </View>
        </View>
        <View
          style={{
            marginBottom: 10,
            marginTop: 10,
            height: 1,
            backgroundColor: "black",
            width: "100%",
          }}
        ></View>
        <View
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SvgQRCode value={props.pedidoImprimir.gid.toString()} />
        </View>

        <Text style={{ textAlign: "center", marginTop: 10 }}>
          GRACIAS POR SU COMPRA
        </Text>
      </View>
    );
  }
  function pintarTicketDatafono() {
    return (
      <View
        collapsable={false}
        ref={(ref) => {
          ticketRefDatafono = ref;
        }}
        style={{ width: 280, backgroundColor: "white", padding: 10 }}
      >
        <Logo width="100" height="50" />
        <Text
          style={{
            color: "black",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 26,
            fontWeight: "bold",
          }}
        >
          {props.pedidoImprimir.order_number}
        </Text>
        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          {props.pedidoImprimir.shop.name}
        </Text>
        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          {props.pedidoImprimir.fechaEntrada}
        </Text>
        <Text
          style={{
            fontSize: 17,
            paddingTop: 4,
            paddingBottom: 4,
            fontWeight: "bold",
          }}
        >
          {props.pedidoImprimir.hora}
        </Text>

        {props.pedidoImprimir.location != "" ? (
          <View
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <Text style={{ fontSize: 17, fontWeight: "bold" }}>
              Localización: {props.pedidoImprimir.location}
            </Text>
          </View>
        ) : null}
        {props.pedidoImprimir.comment != undefined &&
        props.pedidoImprimir.comment != "" ? (
          <View
            style={{
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <Text style={{ fontSize: 17, fontWeight: "bold" }}>
              Comentario:
            </Text>
            <Text style={{ fontSize: 17, fontWeight: "bold" }}>
              {props.pedidoImprimir.comment}
            </Text>
          </View>
        ) : null}
        <View
          style={{
            marginBottom: 10,
            marginTop: 10,
            height: 1,
            backgroundColor: "black",
            width: "100%",
          }}
        ></View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text style={{ fontSize: 17, fontWeight: "bold" }}>Uds producto</Text>
          <Text style={{ fontSize: 17, fontWeight: "bold" }}>Importe</Text>
        </View>

        <View>
          {props.pedidoImprimir.productos.map(function (item: any) {
            if (!item.producto.oculto) {
              return (
                <ProductoResumenCamarero
                  sinPrecio={false}
                  disabled={true}
                  lineaPedido={item}
                  datafono={true}
                ></ProductoResumenCamarero>
              );
            }
          })}
        </View>
        <View
          style={{
            marginBottom: 10,
            marginTop: 10,
            height: 1,
            backgroundColor: "black",
            width: "100%",
          }}
        ></View>
        <View
          style={{
            padding: 10,
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <View style={{ width: "75%" }}>
            {props.pedidoImprimir.order_lines_commission.map(function (
              item: any,
              index: any
            ) {
              if (item.amount > 0) {
                return (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 4,
                      marginBottom: 4,
                    }}
                  >
                    <View style={{ flex: 1, flexDirection: "row" }}>
                      <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                        {item.name}
                      </Text>
                    </View>
                    <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                      {(item.amount / 100).toFixed(2)}€
                    </Text>
                  </View>
                );
              }
            })}
            {props.pedidoImprimir.order_lines_service.map(function (
              item: any,
              index: any
            ) {
              if (item.amount > 0) {
                return (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 4,
                      marginBottom: 4,
                    }}
                  >
                    <View style={{ flex: 1, flexDirection: "row" }}>
                      <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                        {item.name}
                      </Text>
                    </View>
                    <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                      {(item.amount / 100).toFixed(2)}€
                    </Text>
                  </View>
                );
              }
            })}

            {props.pedidoImprimir.order_lines_discount.length > 0
              ? pintarDescuento(props.pedidoImprimir.order_lines_discount)
              : null}
          </View>
          <View style={{ width: "100%" }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                TOTAL{" "}
                <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                  (Impuestos Inc.)
                </Text>
              </Text>
              <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                {props.pedidoImprimir.calcularPrecioPedido().toFixed(2) + "€"}
              </Text>
            </View>
          </View>
          <View style={{ width: "75%" }}>
            {props.pedidoImprimir.payment_method == "dataphone" ||
            props.pedidoImprimir.payment_method == "card" ? (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 4,
                  marginBottom: 4,
                }}
              >
                <View style={{ flex: 1, flexDirection: "row" }}>
                  <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                    Forma de pago
                  </Text>
                </View>
                <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                  Tarjeta
                </Text>
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 4,
                  marginBottom: 4,
                }}
              >
                <View style={{ flex: 1, flexDirection: "row" }}>
                  <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                    Forma de pago
                  </Text>
                </View>
                <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                  Efectivo
                </Text>
              </View>
            )}
          </View>
        </View>
        <View
          style={{
            marginBottom: 10,
            marginTop: 10,
            height: 1,
            backgroundColor: "black",
            width: "100%",
          }}
        ></View>
        <Text
          style={{
            textAlign: "center",
            marginTop: 10,
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          GRACIAS POR SU COMPRA
        </Text>
      </View>
    );
  }

  function guardarPedidoSincronizadoBBDD(pedidoAux: any) {
    return new Promise(async function (resolve, reject) {
      await global.db.transaction(
        (tx: any) => {
          tx.executeSql(
            "CREATE TABLE IF NOT EXISTS pedidosSincronizados(" +
              "pedidosSincronizados TEXT" +
              ");"
          );
        },
        function (err: any) {
          console.log("err", err);
        }
      );

      await global.db.transaction(
        (tx: any) => {
          tx.executeSql(
            "INSERT OR REPLACE INTO pedidosSincronizados(" +
              "pedidosSincronizados" +
              ") VALUES ('" +
              JSON.stringify(pedidoAux) +
              "');"
          );
        },
        function (err: any) {
          console.log("err", err);
        }
      );
    });
  }
  function pintarFacturaProductos() {
    let factura = props.facturaProductos;
    let pedido = new Pedido(factura.order);
    let totalIva = 0;
    if (factura.invoice.invoiceType == "SIMPLIFIED_GOPICK_USER_COMMISSIONS") {
      totalIva =
        Math.round((factura.invoice.totalAmount / 100) * 21 * 100) / 100;
      factura.invoice.taxes = [{ tax: 21, amount: totalIva }];
    } else {
      factura.invoice?.taxes?.map(function (tax: any, index: number) {
        totalIva = totalIva + parseFloat(tax.amount);
      });
    }
    totalIva = Math.round(totalIva * 100) / 100;

    let precioNeto =
      Math.round((factura.invoice.totalAmount - totalIva) * 100) / 100;

    return (
      <View
        collapsable={false}
        ref={(ref) => {
          global.ticketRefFacturaProductos = ref;
        }}
        style={{ width: 350, backgroundColor: "white", padding: 10 }}
      >
        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 26,
            fontWeight: "bold",
          }}
        >
          {factura.order.orderNumber}
        </Text>

        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          {factura.fiscalData.name}
        </Text>
        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          CIF: {factura.fiscalData.tax_id}
        </Text>
        {factura.fiscalData.phone ? (
          <Text
            style={{
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              fontSize: 17,
              fontWeight: "bold",
            }}
          >
            Tlf: {factura.fiscalData.phone}
          </Text>
        ) : null}

        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          {factura.fiscalData.address1}
        </Text>

        {factura.fiscalData.address2 ? (
          <Text
            style={{
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              fontSize: 17,
              fontWeight: "bold",
            }}
          >
            {factura.fiscalData.address2}
          </Text>
        ) : null}
        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          {factura.fiscalData.zipcode}
        </Text>
        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          {factura.fiscalData.city}
        </Text>
        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          {factura.fiscalData.country}
        </Text>

        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          {factura.invoice.number}
        </Text>

        <View
          style={{
            marginBottom: 10,
            marginTop: 10,
            height: 1,
            backgroundColor: "black",
            width: "100%",
          }}
        ></View>

        {factura.invoice.invoiceType == "SIMPLIFIED_CLIENT_USER_PRODUCTS" ? (
          <View>
            {factura.order.location ? (
              <Text
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                  fontSize: 17,
                  fontWeight: "bold",
                }}
              >
                Localización: {factura.order.location}
              </Text>
            ) : null}
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                Uds producto
              </Text>
              <Text style={{ fontSize: 17, fontWeight: "bold" }}>Importe</Text>
            </View>
            {factura.order.orderLines.map(function (item: any) {
              return (
                <ProductoResumenDatafono
                  sinPrecio={false}
                  disabled={true}
                  lineaPedido={item}
                  datafono={true}
                ></ProductoResumenDatafono>
              );
            })}
            <View
              style={{
                marginBottom: 10,
                marginTop: 10,
                height: 1,
                backgroundColor: "black",
                width: "100%",
              }}
            ></View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              <View style={{ flex: 1, flexDirection: "row" }}>
                <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                  Subtotal
                </Text>
              </View>
              <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                {factura.invoice.totalAmountWithoutCommissions}€
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              <View style={{ flex: 1, flexDirection: "row" }}>
                <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                  Servicio
                </Text>
              </View>
              <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                {factura.invoice.servicesAmount}€
              </Text>
            </View>
          </View>
        ) : (
          <View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              <View style={{ flex: 1, flexDirection: "row" }}>
                <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                  Comision por servicio
                </Text>
              </View>
              <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                {factura.invoice.commissionsAmount}€
              </Text>
            </View>

            <View
              style={{
                marginBottom: 10,
                marginTop: 10,
                height: 1,
                backgroundColor: "black",
                width: "100%",
              }}
            ></View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              <View style={{ flex: 1, flexDirection: "row" }}>
                <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                  Subtotal
                </Text>
              </View>
              <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                {factura.invoice.commissionsAmount}€
              </Text>
            </View>
          </View>
        )}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          <View style={{ flex: 1, flexDirection: "row" }}>
            <Text style={{ fontSize: 17, fontWeight: "bold" }}>Total neto</Text>
          </View>
          <Text style={{ fontSize: 17, fontWeight: "bold" }}>
            {precioNeto}€
          </Text>
        </View>

        {factura.invoice.taxes.map(function (item: any, index: any) {
          return (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              <View style={{ flex: 1, flexDirection: "row" }}>
                <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                  IVA ({item.tax}%)
                </Text>
              </View>
              <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                {item.amount}€
              </Text>
            </View>
          );
        })}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          <View style={{ flex: 1, flexDirection: "row" }}>
            <Text style={{ fontSize: 17, fontWeight: "bold" }}>Total</Text>
          </View>
          <Text style={{ fontSize: 17, fontWeight: "bold" }}>
            {factura.invoice.totalAmount}€
          </Text>
        </View>

        <View
          style={{
            marginBottom: 10,
            marginTop: 10,
            height: 1,
            backgroundColor: "black",
            width: "100%",
          }}
        ></View>
        {factura.ticket_bai_url != "" ? (
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SvgQRCode value={factura.ticket_bai_url} />
          </View>
        ) : null}
        <Text style={{ textAlign: "center", marginTop: 10 }}>
          GRACIAS POR SU COMPRA
        </Text>
      </View>
    );
  }
  function pintarFacturaComision() {
    let factura = props.facturaComision;
    let pedido = new Pedido(factura.order);
    console.log("pintarFactura factura", factura);
    console.log("pintarFactura factura", pedido);
    let totalIva = 0;
    if (factura.invoice.invoiceType == "SIMPLIFIED_GOPICK_USER_COMMISSIONS") {
      totalIva =
        Math.round((factura.invoice.totalAmount / 100) * 21 * 100) / 100;
      factura.invoice.taxes = [{ tax: 21, amount: totalIva }];
    } else {
      factura.invoice?.taxes?.map(function (tax: any, index: number) {
        totalIva = totalIva + parseFloat(tax.amount);
      });
    }
    totalIva = Math.round(totalIva * 100) / 100;

    let precioNeto =
      Math.round((factura.invoice.totalAmount - totalIva) * 100) / 100;

    return (
      <View
        collapsable={false}
        ref={(ref) => {
          global.ticketRefFacturaComision = ref;
        }}
        style={{ width: 350, backgroundColor: "white", padding: 10 }}
      >
        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 26,
            fontWeight: "bold",
          }}
        >
          {factura.order.orderNumber}
        </Text>

        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          {factura.fiscalData.name}
        </Text>
        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          CIF: {factura.fiscalData.tax_id}
        </Text>
        {factura.fiscalData.phone ? (
          <Text
            style={{
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              fontSize: 17,
              fontWeight: "bold",
            }}
          >
            Tlf: {factura.fiscalData.phone}
          </Text>
        ) : null}

        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          {factura.fiscalData.address1}
        </Text>

        {factura.fiscalData.address2 ? (
          <Text
            style={{
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              fontSize: 17,
              fontWeight: "bold",
            }}
          >
            {factura.fiscalData.address2}
          </Text>
        ) : null}
        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          {factura.fiscalData.zipcode}
        </Text>
        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          {factura.fiscalData.city}
        </Text>
        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          {factura.fiscalData.country}
        </Text>

        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          {factura.invoice.number}
        </Text>

        <View
          style={{
            marginBottom: 10,
            marginTop: 10,
            height: 1,
            backgroundColor: "black",
            width: "100%",
          }}
        ></View>

        {factura.invoice.invoiceType == "SIMPLIFIED_CLIENT_USER_PRODUCTS" ? (
          <View>
            {factura.order.location ? (
              <Text
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                  fontSize: 17,
                  fontWeight: "bold",
                }}
              >
                Localización: {factura.order.location}
              </Text>
            ) : null}
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                Uds producto
              </Text>
              <Text style={{ fontSize: 17, fontWeight: "bold" }}>Importe</Text>
            </View>
            {factura.order.orderLines.map(function (item: any) {
              return (
                <ProductoResumenDatafono
                  sinPrecio={false}
                  disabled={true}
                  lineaPedido={item}
                  datafono={true}
                ></ProductoResumenDatafono>
              );
            })}
            <View
              style={{
                marginBottom: 10,
                marginTop: 10,
                height: 1,
                backgroundColor: "black",
                width: "100%",
              }}
            ></View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              <View style={{ flex: 1, flexDirection: "row" }}>
                <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                  Subtotal
                </Text>
              </View>
              <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                {factura.invoice.totalAmountWithoutCommissions}€
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              <View style={{ flex: 1, flexDirection: "row" }}>
                <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                  Servicio
                </Text>
              </View>
              <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                {factura.invoice.servicesAmount}€
              </Text>
            </View>
          </View>
        ) : (
          <View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              <View style={{ flex: 1, flexDirection: "row" }}>
                <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                  Comision por servicio
                </Text>
              </View>
              <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                {factura.invoice.commissionsAmount}€
              </Text>
            </View>

            <View
              style={{
                marginBottom: 10,
                marginTop: 10,
                height: 1,
                backgroundColor: "black",
                width: "100%",
              }}
            ></View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              <View style={{ flex: 1, flexDirection: "row" }}>
                <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                  Subtotal
                </Text>
              </View>
              <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                {factura.invoice.commissionsAmount}€
              </Text>
            </View>
          </View>
        )}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          <View style={{ flex: 1, flexDirection: "row" }}>
            <Text style={{ fontSize: 17, fontWeight: "bold" }}>Total neto</Text>
          </View>
          <Text style={{ fontSize: 17, fontWeight: "bold" }}>
            {precioNeto}€
          </Text>
        </View>

        {factura.invoice.taxes.map(function (item: any, index: any) {
          return (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              <View style={{ flex: 1, flexDirection: "row" }}>
                <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                  IVA ({item.tax}%)
                </Text>
              </View>
              <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                {item.amount}€
              </Text>
            </View>
          );
        })}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          <View style={{ flex: 1, flexDirection: "row" }}>
            <Text style={{ fontSize: 17, fontWeight: "bold" }}>Total</Text>
          </View>
          <Text style={{ fontSize: 17, fontWeight: "bold" }}>
            {factura.invoice.totalAmount}€
          </Text>
        </View>

        <View
          style={{
            marginBottom: 10,
            marginTop: 10,
            height: 1,
            backgroundColor: "black",
            width: "100%",
          }}
        ></View>
        {factura.ticket_bai_url != "" ? (
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SvgQRCode value={factura.ticket_bai_url} />
          </View>
        ) : null}
        <Text style={{ textAlign: "center", marginTop: 10 }}>
          GRACIAS POR SU COMPRA
        </Text>
      </View>
    );
  }
  function pintarTicketManager() {
    return (
      <View
        collapsable={false}
        ref={(ref) => {
          ticketRefManager = ref;
        }}
        style={{ width: 350, backgroundColor: "white", padding: 10 }}
      >
        <Text
          style={{
            color: "#3AA597",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 28,
            fontWeight: "bold",
          }}
        >
          {props.pedidoImprimir.order_number}
        </Text>
        <Text
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 15,
          }}
        >
          {props.pedidoImprimir.fechaEntrada}
        </Text>
        {props.pedidoImprimir.location != "" ? (
          <Text
            style={{
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {props.pedidoImprimir.location}
          </Text>
        ) : null}
        <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
          <Text>{props.pedidoImprimir.shop.name}</Text>
          <Text>{props.pedidoImprimir.hora}</Text>
        </View>
        {props.pedidoImprimir.comment != undefined &&
        props.pedidoImprimir.comment != "" ? (
          <View
            style={{
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              paddingHorizontal: 10,
              paddingVertical: 4,
            }}
          >
            <Text style={{ fontSize: 15, fontWeight: "bold" }}>
              Comentario:
            </Text>
            <Text style={{ fontSize: 15 }}>{props.pedidoImprimir.comment}</Text>
          </View>
        ) : null}

        <View
          style={{
            marginBottom: 10,
            marginTop: 10,
            height: 1,
            backgroundColor: "black",
            width: "100%",
          }}
        ></View>

        <View style={{ paddingHorizontal: 10 }}>
          {props.pedidoImprimir.productos.map(function (item: any) {
            if (!item.producto.oculto) {
              return (
                <ProductoResumenCamarero
                  sinPrecio={true}
                  disabled={true}
                  lineaPedido={item}
                ></ProductoResumenCamarero>
              );
            }
          })}
        </View>
      </View>
    );
  }
  function reImprimirFacturas(pedido: any) {
    global.usuario.obtenerFacturas(pedido.gid).then(async function (res: any) {
      if (res?.data?.data?.length > 0) {
        if (res?.data?.data?.length > 0) {
          for (var i = res?.data?.data?.length - 1; i >= 0; i--) {
            let item = res.data.data[i];
            let factura = item.data;
            factura.ticket_bai_url = item.ticket_bai_url || "";
            if (i == 0) {
              props.setFacturaProductos(factura);
              setTimeout(async function () {
                await myCrypto.imprimirAdyen(global.ticketRefFacturaProductos);
              }, 1000);
            }
            if (i == 1) {
              props.setFacturaComision(factura);
              setTimeout(async function () {
                await myCrypto.imprimirAdyen(global.ticketRefFacturaComision);
              }, 1000);
            }
          }
        }
      }
    });
  }

  return (
    <View
      style={{
        flex: 1,
        alignItems: "flex-start",
        backgroundColor: "white",
        justifyContent: "flex-start",
      }}
    >
      <ScrollView
        style={[
          {
            paddingTop: 30,
            width: "100%",
            zIndex: 2,
            position: "absolute",
            backgroundColor: "white",
          },
          global.pantallaDividida
            ? { height: props.height - 60 }
            : { height: props.height - 120 },
        ]}
        contentContainerStyle={{
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 60,
          backgroundColor: "white",
        }}
      >
        {/* <MenuButton
          title={"TPV"}
          icon={require("../../assets/icons/imgtpv.png")}
          onPress={abrirTiendas.bind(this, "tpv")}
        ></MenuButton>
        <MenuButton
          title={"Camarero"}
          icon={require("../../assets/icons/imgcamarero.png")}
          onPress={abrirTiendas.bind(this, "waiter")}
        ></MenuButton>
        <MenuButton
          title={"Gestor de pedidos"}
          icon={require("../../assets/icons/imggestor.png")}
          onPress={abrirPedidos}
        ></MenuButton>
        <MenuButton
          title={"Validador"}
          icon={require("../../assets/icons/imgvalidador.png")}
          onPress={abrirEscaner}
        ></MenuButton>
        <MenuButton
          title={"Kiosko"}
          icon={require("../../assets/icons/imgcamarero.png")}
          onPress={abrirTiendas.bind(this, "foodStall")}
        ></MenuButton> */}
        <MenuButton
          title={"Comandero"}
          icon={require("../../assets/icons/comandero.jpeg")}
          onPress={() => props.navigation.navigate("FoodStallOrdersScreen")}
        ></MenuButton>
      </ScrollView>
      <View style={{ position: "absolute", width: "100%", zIndex: -1 }}>
        {props.pedidoImprimir != undefined && props.pedidoImprimir != null ? (
          <View style={{ width: "100%" }}>{pintarTicketCliente()}</View>
        ) : null}
      </View>
      <View style={{ position: "absolute", width: "100%", zIndex: -1 }}>
        {props.pedidoImprimir != undefined && props.pedidoImprimir != null ? (
          <View style={{ width: "100%" }}>{pintarTicketManager()}</View>
        ) : null}
      </View>
      <View style={{ position: "absolute", width: "100%", zIndex: -1 }}>
        {props.pedidoImprimir != undefined && props.pedidoImprimir != null ? (
          <View style={{ width: "100%" }}>{pintarTicketDatafono()}</View>
        ) : null}
      </View>
      <View style={{ position: "absolute", width: "100%", zIndex: -1 }}>
        {props.facturaProductos != undefined &&
        props.facturaProductos != null ? (
          <View style={{ width: "100%" }}>{pintarFacturaProductos()}</View>
        ) : null}
      </View>
      <View style={{ position: "absolute", width: "100%", zIndex: -1 }}>
        {props.facturaComision != undefined && props.facturaComision != null ? (
          <View style={{ width: "100%" }}>{pintarFacturaComision()}</View>
        ) : null}
      </View>
    </View>
  );
};
const mapStateToProps = (state: any) => {
  return {
    tiendas: state.tiendas.tiendas,
    width: state.dimensions.width,
    height: state.dimensions.height,
    pedidos: state.pedidos.pedidos,
    pedidoImprimir: state.pedidoImprimir.pedidoImprimir,
    facturaProductos: state.pedidoImprimir.facturaProductos,
    facturaComision: state.pedidoImprimir.facturaComision,
    pedidosPendientes: state.pedidoImprimir.pedidosPendientes,
    impresoraDisponible: state.pedidoImprimir.impresoraDisponible,
    conexion: state.dimensions.conexion,
    referenciaTicket: state.pedidoImprimir.referenciaTicket,
  };
};
const mapDispatchToProps = {
  setPedidoImprimir,
  setPedidos,
  setPedidosFiltrados,
  addPedido,
  editarPedido,
  setStock,
  setConexion,
  setTiendas,
  setPedidosPendientes,
  setImpresoraDisponible,
  setTiendasCarrito,
  setSocketConectado,
  setPedidosPerdidos,
  setFooterVisible,
  setPedidosParaEntregar,
  setPedidosEnviados,
  setPedidosCompletados,
  setPedidosPreparar,
  setFacturaProductos,
  setFacturaComision,
};
export default connect(mapStateToProps, mapDispatchToProps)(menu);
